/*
  a grouping of products to be treated as a single unit for the purpose of a contract definition
  the thinking is that this can allow for "batching" (often sold as its own plugin)
  "batching" is important because it allows for discounts to happen for specific product combinations

  NOTE: as soon as there are "CustomerPurchases" corresponding to a purchasable, it must become immutable
*/
import { clamp } from "lodash";
import { ProductVariantWeightUnit } from "shopify-api-node";

import { CurrencyCode } from "@smartrr/shared/currencyCode";

import { ProductVariantInventoryPolicy } from "../../shopifyGraphQL/api";
import { BigIntString } from "../BigIntString";
import { ISmartrrAddOnsConfigSellingPlan, ISmartrrSellingPlan } from "../SellingPlanGroup";
import { ICreateEntity, IShopifyMirroredEntityFields } from "../shared/SharedEntityFields";

/*
  NOTE: corresponds one-to-one with a Shopify "Product"
*/
export interface IPurchasableVariant extends IShopifyMirroredEntityFields {
  // fields
  shopifyProductVariantStorefrontId?: string;
  purchasableVariantName?: string;
  purchasableVariantImages?: string[];
  purchasableVariantSmallImages?: string[];
  isActiveInShopify: boolean;
  isDraftOrArchived?: boolean;

  shopifyInventoryId?: string;
  requiresShipping: boolean;
  sku?: string;
  unitCost?: number;

  presentmentPrices: PresentmentPrice[];

  inventoryQuantity?: number | null;
  inventoryPolicy?: `${ProductVariantInventoryPolicy}` | null;
  weight?: number;
  weight_unit?: ProductVariantWeightUnit;
}

export interface PresentmentPrice {
  price: BigIntString;
  priceCurrency: CurrencyCode;
}

export type IPurchasableVariantCreate = ICreateEntity<IPurchasableVariant>;

export function getVariantPriceForCurrency(
  variant: IPurchasableVariant,
  currency: CurrencyCode
): BigIntString | undefined {
  return variant.presentmentPrices.find(presentmentPrice => presentmentPrice.priceCurrency === currency)?.price;
}

export function getSmartrrVariantDiscountPrice(
  variant: IPurchasableVariant,
  currency: CurrencyCode,
  sellingPlan?: ISmartrrSellingPlan | ISmartrrAddOnsConfigSellingPlan
): number {
  if (variant === null || variant === undefined) {
    return 0;
  }

  const variantPriceForCurrency = getVariantPriceForCurrency(variant, currency);

  if (variantPriceForCurrency === undefined) {
    return 0;
  }

  const variantPrice = BigIntString.fromBigIntString(variantPriceForCurrency!);

  if (!sellingPlan) {
    return variantPrice;
  }

  const policy = sellingPlan.pricingPolicies[0];
  let price = variantPrice;

  if (policy) {
    switch (policy.adjustmentType) {
      case "FIXED_AMOUNT": {
        price = variantPrice - policy.adjustmentValue.amount * 100;
        break;
      }
      case "PERCENTAGE": {
        price = variantPrice - (variantPrice * policy.adjustmentValue.percentage) / 100;
        break;
      }
      case "PRICE": {
        price = policy.adjustmentValue.amount * 100;
        break;
      }
    }
  }

  price = clamp(price, 0, variantPrice);

  return price;
}

export function getVariantPriceForCurrencyWithAFallbackValue(
  variant: IPurchasableVariant | null,
  currency: CurrencyCode
): BigIntString | undefined {
  const priceForCurrency = variant?.presentmentPrices.find(
    presentmentPrice => presentmentPrice.priceCurrency === currency
  )?.price;

  if (!priceForCurrency) {
    return variant?.presentmentPrices.find(presentmentPrice => !!presentmentPrice.price)?.price ?? "0";
  }

  return priceForCurrency;
}
