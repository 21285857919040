import {
  ISmartrrAddOnsConfigSellingPlan,
  ISmartrrSellingPlan,
  SmartrrSellingPlanPricingPolicy,
} from "@smartrr/shared/entities/SellingPlanGroup";

import { BigIntString } from "../../entities/BigIntString";
import {
  IPurchasableVariant,
  getVariantPriceForCurrency,
  getVariantPriceForCurrencyWithAFallbackValue,
} from "../../entities/PurchasableVariant";
import {
  IPricingPolicyCycleAdjustment,
  IPurchaseStateLineItem,
  IPurchaseStateLineItemPricingPolicy,
} from "../../entities/PurchaseState/CustomerPurchaseLineItem";
import {
  CurrencyCode,
  MoneyV2,
  SellingPlanFragmentFragment,
  SellingPlanPricingPolicyAdjustmentType,
  SellingPlanPricingPolicyAdjustmentValue,
  SellingPlanPricingPolicyPercentageValue,
  SubscriptionPricingPolicyInput,
} from "../../shopifyGraphQL/api";
import { convertNumberForFormatMoney, toShopifyDecimal, unformatMoney } from "../formatMoney";
import { SurfaceableError } from "../SurfaceableError";

export function getPricingPolicyInputFromLineItem(lineItem: IPurchaseStateLineItem) {
  const currencyCode = lineItem.priceCurrency as CurrencyCode;
  const basePrice = BigIntString.fromBigIntString(lineItem.basePrice);

  const policyInput: SubscriptionPricingPolicyInput = {
    basePrice: "" + convertNumberForFormatMoney(basePrice, currencyCode),
    cycleDiscounts: [],
  };

  if (lineItem.pricingPolicy?.cycleDiscounts.length) {
    const pricingPolicy = lineItem.pricingPolicy as IPurchaseStateLineItemPricingPolicy;
    policyInput.cycleDiscounts = pricingPolicy.cycleDiscounts.map(cycleDiscount => {
      const adjustmentValue =
        cycleDiscount.adjustmentValue.__typename === "SellingPlanPricingPolicyPercentageValue"
          ? {
              percentage: (cycleDiscount.adjustmentValue as SellingPlanPricingPolicyPercentageValue).percentage,
            }
          : {
              fixedValue: (cycleDiscount.adjustmentValue as MoneyV2).amount,
            };
      const adjustedPrice = getPricingPolicyAdjustedPrice(
        basePrice,
        currencyCode,
        cycleDiscount.adjustmentType,
        cycleDiscount.adjustmentValue
      );

      return {
        adjustmentType: cycleDiscount.adjustmentType,
        adjustmentValue,
        afterCycle: cycleDiscount.afterCycle,
        computedPrice: "" + convertNumberForFormatMoney(adjustedPrice, currencyCode),
      };
    });
  }

  return policyInput;
}

export function getPricingPolicyInputFromSellingPlan(
  variant: IPurchasableVariant,
  currencyCode: CurrencyCode,
  sellingPlan: SellingPlanFragmentFragment
): SubscriptionPricingPolicyInput {
  const variantPriceString = getVariantPriceForCurrency(variant, currencyCode);
  if (!variantPriceString) {
    throw new SurfaceableError(`No price for variant "${variant.id}" and currency "${currencyCode}"`);
  }
  const variantUnitPrice = BigIntString.fromBigIntString(variantPriceString);
  let variantPrice = variantUnitPrice;

  // if it involves a prepaid selling plan, we want to calculate the total price of all deliveries.
  if (
    sellingPlan.billingPolicy.__typename === "SellingPlanRecurringBillingPolicy" &&
    sellingPlan.deliveryPolicy.__typename === "SellingPlanRecurringDeliveryPolicy"
  ) {
    if (sellingPlan.billingPolicy.intervalCount !== sellingPlan.deliveryPolicy.intervalCount) {
      const deliveriesInCycle =
        sellingPlan.billingPolicy.intervalCount / sellingPlan.deliveryPolicy.intervalCount;
      variantPrice *= deliveriesInCycle;
    }
  }

  const cycleDiscounts = sellingPlan.pricingPolicies.map(pricingPolicy => {
    const adjustmentValue =
      pricingPolicy.adjustmentValue.__typename === "SellingPlanPricingPolicyPercentageValue"
        ? {
            percentage: (pricingPolicy.adjustmentValue as SellingPlanPricingPolicyPercentageValue).percentage,
          }
        : {
            fixedValue: (pricingPolicy.adjustmentValue as MoneyV2).amount,
          };

    const adjustedPrice = getPricingPolicyAdjustedPrice(
      variantPrice,
      currencyCode,
      pricingPolicy.adjustmentType,
      pricingPolicy.adjustmentValue
    );

    const afterCycle =
      pricingPolicy.__typename === "SellingPlanRecurringPricingPolicy" ? pricingPolicy.afterCycle || 0 : 0;

    return {
      adjustmentType: pricingPolicy.adjustmentType,
      adjustmentValue,
      afterCycle,
      computedPrice: "" + convertNumberForFormatMoney(adjustedPrice, currencyCode),
    };
  });

  return {
    basePrice: "" + convertNumberForFormatMoney(variantUnitPrice, currencyCode),
    cycleDiscounts,
  };
}

export function getLineItemPricingPolicyFromPricingPolicyInput(
  policyInput: SubscriptionPricingPolicyInput,
  currencyCode: CurrencyCode
) {
  const cycleDiscounts = policyInput.cycleDiscounts.map(cycleDiscount => ({
    adjustmentType: cycleDiscount.adjustmentType,
    adjustmentValue: cycleDiscount.adjustmentValue.fixedValue
      ? ({
          amount: cycleDiscount.adjustmentValue.fixedValue,
          currencyCode,
        } as MoneyV2)
      : ({
          percentage: cycleDiscount.adjustmentValue.percentage,
        } as SellingPlanPricingPolicyPercentageValue),
    afterCycle: cycleDiscount.afterCycle,
    computedPrice: {
      currencyCode,
      amount: cycleDiscount.computedPrice,
    },
  }));

  return {
    basePrice: {
      amount: policyInput.basePrice,
      currencyCode,
    },
    cycleDiscounts,
  };
}

export function getPricingPolicyCycleAdjustment(
  adjustmentType: SellingPlanPricingPolicyAdjustmentType,
  adjustmentValue: SellingPlanPricingPolicyAdjustmentValue
): IPricingPolicyCycleAdjustment {
  switch (adjustmentType) {
    case SellingPlanPricingPolicyAdjustmentType.FixedAmount:
    case SellingPlanPricingPolicyAdjustmentType.Price: {
      if (adjustmentValue.__typename !== "MoneyV2") {
        throw new Error(
          `Incompatible adjustment type ${adjustmentType} and value type ${adjustmentValue.__typename}`
        );
      }
      return {
        adjustmentType,
        adjustmentValue: { fixedValue: adjustmentValue.amount },
      };
    }

    case SellingPlanPricingPolicyAdjustmentType.Percentage: {
      if (adjustmentValue.__typename !== "SellingPlanPricingPolicyPercentageValue") {
        throw new Error(
          `Incompatible adjustment type ${adjustmentType} and value type ${adjustmentValue.__typename}`
        );
      }
      return {
        adjustmentType,
        adjustmentValue: { percentage: adjustmentValue.percentage },
      };
    }

    default: {
      const exhaustiveCheck: never = adjustmentType;
      return exhaustiveCheck;
    }
  }
}

export function getAdjustedPriceForAdjustment(
  baseValue: number,
  currency: CurrencyCode,
  adjustment: IPricingPolicyCycleAdjustment
): number {
  switch (adjustment.adjustmentType) {
    case SellingPlanPricingPolicyAdjustmentType.FixedAmount: {
      // Do not let adjusted price to go below zero
      return Math.max(baseValue - unformatMoney(adjustment.adjustmentValue.fixedValue, currency), 0);
    }

    case SellingPlanPricingPolicyAdjustmentType.Price: {
      return unformatMoney(adjustment.adjustmentValue.fixedValue, currency);
    }

    case SellingPlanPricingPolicyAdjustmentType.Percentage: {
      return baseValue - (baseValue * adjustment.adjustmentValue.percentage) / 100;
    }

    default: {
      const exhaustiveCheck: never = adjustment;
      return exhaustiveCheck;
    }
  }
}

export function getPricingPolicyAdjustedPrice(
  baseValue: number,
  currency: CurrencyCode,
  adjustmentType: SellingPlanPricingPolicyAdjustmentType,
  adjustmentValue: SellingPlanPricingPolicyAdjustmentValue
) {
  const adjustment = getPricingPolicyCycleAdjustment(adjustmentType, adjustmentValue);

  return getAdjustedPriceForAdjustment(baseValue, currency, adjustment);
}

export function buildFixedPricePricingPolicy(basePrice: any, fixedValue: number): SubscriptionPricingPolicyInput {
  return {
    basePrice,
    cycleDiscounts: [
      {
        adjustmentType: SellingPlanPricingPolicyAdjustmentType.Price,
        adjustmentValue: { fixedValue: toShopifyDecimal(fixedValue) },
        afterCycle: 0,
        computedPrice: toShopifyDecimal(fixedValue),
      },
    ],
  };
}

const isSmartrrSellingPlan = (x: any): x is ISmartrrSellingPlan =>
  x.hasOwnProperty("billingPolicy") && x.hasOwnProperty("deliveryPolicy");

export function getPricingPolicyInputFromSmartrrSellingPlan(
  variant: IPurchasableVariant,
  currencyCode: CurrencyCode,
  sellingPlan: ISmartrrSellingPlan | ISmartrrAddOnsConfigSellingPlan
): SubscriptionPricingPolicyInput {
  const variantPriceString = getVariantPriceForCurrencyWithAFallbackValue(variant, currencyCode);
  if (!variantPriceString) {
    throw new SurfaceableError(`No price for variant "${variant.id}" and currency "${currencyCode}"`);
  }
  let variantPrice = BigIntString.fromBigIntString(variantPriceString);

  // if it involves a prepaid selling plan, we want to calculate the total price of all deliveries.

  if (
    isSmartrrSellingPlan(sellingPlan) &&
    sellingPlan.billingPolicy.intervalCount !== sellingPlan.deliveryPolicy.intervalCount
  ) {
    const deliveriesInCycle = sellingPlan.billingPolicy.intervalCount / sellingPlan.deliveryPolicy.intervalCount;
    variantPrice *= deliveriesInCycle;
  }

  const cycleDiscounts = sellingPlan.pricingPolicies.map(pricingPolicy => {
    const adjustmentValue =
      pricingPolicy.adjustmentType === "PERCENTAGE"
        ? {
            percentage: (pricingPolicy.adjustmentValue as SellingPlanPricingPolicyPercentageValue).percentage,
          }
        : {
            fixedValue: (pricingPolicy.adjustmentValue as MoneyV2).amount,
          };

    const adjustedPrice = getAdjustedPriceForAdjustmentForSmartrrPlan(variantPrice, currencyCode, pricingPolicy);

    const afterCycle =
      pricingPolicy.pricingPolicyType === "SellingPlanRecurringPricingPolicy" ? pricingPolicy.afterCycle || 0 : 0;

    return {
      adjustmentType: formatSmartrrPolicyAdjustmentTypeToSubscriptionPricingPolicyAdjustmentType(pricingPolicy),
      adjustmentValue,
      afterCycle,
      computedPrice: "" + convertNumberForFormatMoney(adjustedPrice, currencyCode),
    };
  });

  return {
    basePrice: "" + convertNumberForFormatMoney(variantPrice, currencyCode),
    cycleDiscounts,
  };
}

export const formatSmartrrPolicyAdjustmentTypeToSubscriptionPricingPolicyAdjustmentType = (
  policy: SmartrrSellingPlanPricingPolicy
) => {
  switch (policy.adjustmentType) {
    case "FIXED_AMOUNT": {
      return SellingPlanPricingPolicyAdjustmentType.FixedAmount;
    }
    case "PERCENTAGE": {
      return SellingPlanPricingPolicyAdjustmentType.Percentage;
    }
    case "PRICE": {
      return SellingPlanPricingPolicyAdjustmentType.Price;
    }
  }
};

export function getAdjustedPriceForAdjustmentForSmartrrPlan(
  baseValue: number,
  currency: CurrencyCode,
  policy: SmartrrSellingPlanPricingPolicy
): number {
  switch (policy.adjustmentType) {
    case "FIXED_AMOUNT": {
      // Do not let adjusted price to go below zero
      return Math.max(baseValue - unformatMoney(policy.adjustmentValue.amount, currency), 0);
    }

    case "PRICE": {
      return unformatMoney(policy.adjustmentValue.amount, currency);
    }

    case "PERCENTAGE": {
      return baseValue - (baseValue * policy.adjustmentValue.percentage) / 100;
    }

    default: {
      const exhaustiveCheck: never = policy;
      return exhaustiveCheck;
    }
  }
}
